<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Invoice</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="save" method="post" id="invoice-form">
          <v-text-field
            label="Invoice Number"
            v-model="fields.invoice_number"
            background-color="white"
            outlined
            :error="errors.hasOwnProperty('invoice_number')"
            :error-messages="errors['invoice_number']"
          ></v-text-field>
          <v-menu
            v-model="datePicker"
            transition="scale-transition"
            offset-y
            min-width="290"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Balance Due Date"
                v-model="balanceDueDateFormatted"
                background-color="white"
                outlined
                :error="errors.hasOwnProperty('balance_due_date')"
                :error-messages="errors['balance_due_date']"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.balance_due_date"
              @input="datePicker = false"
              first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
          <v-textarea
            label="Abbreviated Transport Summary"
            v-model="fields.short_transport_summary"
            background-color="white"
            rows="6"
            outlined
            :error="errors.hasOwnProperty('short_transport_summary')"
            :error-messages="errors['short_transport_summary']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="invoice-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      datePicker: false,
      fields: {
        invoice_number: null,
        balance_due_date: null,
        short_transport_summary: null,
      },
      errors: {},
    };
  },

  computed: {
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },

    balanceDueDateFormatted() {
      return this.formatDate(this.fields.balance_due_date);
    },
  },

  methods: {
    openForm() {
      this.fields.invoice_number = this.itinerary.invoice_number;
      this.fields.balance_due_date = this.itinerary.balance_due_date;
      this.fields.short_transport_summary = this.itinerary.short_transport_summary;

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.fields.invoice_number = null;
      this.fields.balance_due_date = null;
      this.fields.short_transport_summary = null;
      this.errors = {};
    },

    save() {
      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("drum/tours/saveItineraryInvoicing", {
          appId: this.$route.params.id,
          tourId: this.$route.params.tourId,
          itineraryId: this.$route.params.itineraryId,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
