<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Invoices</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            dark
            class="mr-2"
            :loading="pdfLoading"
            @click="downloadPdf"
          >
            <v-icon small left>mdi-file-table</v-icon> Download Invoice
          </v-btn>
          <v-btn
            small
            depressed
            color="blue white--text"
            @click="$refs.invoiceForm.openForm()"
          >
            <v-icon small left>mdi-pencil</v-icon> Edit
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Invoice Number
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.invoice_number"
            v-html="itinerary.invoice_number"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Balance Due Date
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.balance_due_date"
            v-html="itinerary.formatted_dates.balance_due_date"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Abbreviated Transport Summary
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.short_transport_summary"
            v-html="itinerary.short_transport_summary"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
      </v-col>
    </v-row>
    <invoice-form ref="invoiceForm" />
  </div>
</template>

<script>
import InvoiceForm from "./components/InvoiceForm.vue";

export default {
  components: {
    InvoiceForm,
  },

  data() {
    return {
      pdfLoading: false,
    };
  },

  computed: {
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },
  },

  methods: {
    downloadPdf() {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.pdfLoading = true;

      this.$store
        .dispatch("drum/tours/downloadItineraryPricingPdf", {
          appId,
          tourId,
          itineraryId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "itinerary-pricing.pdf";
          link.click();
          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },
  },
};
</script>
